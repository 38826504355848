.user-nft-explorer-overview-wrapper {
  border-radius: 10px;
}

.user-nft-explorer-overview-nft-club-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.user-nft-explorer-overview-nft-header-name {
  font-family: "Manrope";
  font-weight: 700;
  font-size: 32px;
  color: #000000;
  padding-bottom: 5px;
}

.user-nft-explorer-overview-nft-club-wrapper {
  display: flex;
  align-items: center;
}

.user-nft-explorer-overview-nft-name {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin: 0 5px;
}

.user-nft-explorer-overview-nft-club-image {
  width: 30px;
  height: 30px;
  border: 0.5px solid #dddddd;
  border-radius: 5px;
  object-fit: contain;
}

.user-nft-explorer-overview-nft-verify-button {
  background: #056af9;
  border-radius: 22px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 14px;
  text-transform: uppercase;
  color: white;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  cursor: pointer;

  &:hover {
    background: rgb(5, 106, 249, 0.9);
  }
}

.user-nft-explorer-overview-nft-verify-button-image {
  margin-right: 5px;
}

.button-nft-transaction-navigator {
  text-decoration: none;

  &:visited {
    text-decoration: none;

    &:link {
      text-decoration: none;
    }
  }
}

.user-nft-explorer-overview-nft-details {
  border-radius: 10px;
}

.user-nft-explorer-overview-nft-details-tokenId {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #121212;
  border: 2px solid #949494;
  padding: 10px 30px;
  border-radius: 10px;
  margin-right: 10px;
}

.user-nft-explorer-overview-nft-details-tokenId-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #797979;
  text-transform: uppercase;
}

#user-nft-explorer-overview-nft-details-tokenId-id {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}

.user-nft-explorer-overview-nft-details-owner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #121212;
  border: 2px solid #949494;
  padding: 10px 30px;
  border-radius: 10px;
}

.user-nft-explorer-overview-nft-details-owner-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #797979;
  text-transform: uppercase;
}

.user-nft-explorer-overview-nft-details-owner-id {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  display: flex;
}

.user-nft-explorer-overview-nft-details-Row {
  display: flex;
  gap: 20px;

  p {
    &:first-child {
      width: 100%;
      max-width: 135px;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #121212;
      padding-bottom: 10px;
    }

    &:last-child {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #121212;
    }
  }
}

.user-nft-explorer-overview-nft-details-information-icon {
  border-radius: 50%;
  border: 1.5px solid black;
  height: 12px;
  width: 12px;
  padding: 1px;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
}

.user-nft-explorer-overview-nft-details-properties {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  padding: 9px 11px;
  background: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-nft-explorer-overview-nft-details-about {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  padding: 9px 11px;
  background: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-nft-explorer-overview-nft-details-etherscan {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  padding: 9px 11px;
  background: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.user-nft-explorer-overview-nft-details-etherscan-link {
  text-decoration: none;

  &:link {
    text-decoration: none;

    &:visited {
      text-decoration: none;
    }
  }
}

.user-nft-explorer-overview-nft-details-button-icons {
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

.nft-overview-popup-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  padding-top: 30px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.nft-overview-popup-modal-content {
  margin: 0 auto;
  width: 40%;
  max-height: 70%;
  overflow: auto;
}

.close-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Manrope";
  font-style: normal;
  color: #fe613b;
  float: right;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #fe613b;
  padding: 1px 5px;
  border-radius: 30px;
  margin: 15px 30px 0 0;
  letter-spacing: 1.1px;
}

.close-popup:hover,
.close-popup:focus {
  color: #fe613b;
  text-decoration: none;
  cursor: pointer;
}

.close-popup-icon {
  font-size: 14px;
  margin-right: 5px;
  vertical-align: middle;
}
