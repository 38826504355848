@import "./variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

html,
body {
  font-size: 10px;
  font-family: "Manrope", sans-serif;
  background-color: $color-white-1;
  -webkit-font-smoothing: antialiased;
}

.container {
  padding: 0 24px;
  margin: auto;
  @media (min-width: 1040px) {
    max-width: 992px;
    padding: 0;
  }
}
