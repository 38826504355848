.user-nft-explorer-image-block {
  position: relative;
  width: 445px;
  height: 445px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-nft-explorer-image {
  position: absolute;
  padding: 10px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: contain;
  border: none;
}
