.user-nft-explorer-about-wrapper {
  border-radius: 10px;
}

.user-nft-explorer-about-nft-details {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.3);
}

.user-nft-explorer-about-nft-details-header-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #121212;
  padding: 15px 20px;
  border-bottom: 1px solid #dddddd;
}

.user-nft-explorer-about-nft-details-header-icon {
  margin-right: 5px;
}

.user-nft-explorer-about-nft-club-details {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #dddddd;
}

.user-nft-explorer-about-nft-club-image {
  width: 71px;
  height: 71px;
  border: 0.5px solid #dddddd;
  border-radius: 5px;
  object-fit: contain;
  margin-right: 10px;
  margin-left: 20px;
}

.user-nft-explorer-about-collection-not-added {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin: 0 5px;
}
.user-nft-explorer-about-nft-name {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.user-nft-explorer-about-nft-verify-icon {
  margin-left: 5px;
}

.user-nft-explorer-about-nft-token {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #797979;
}

.user-nft-explorer-about-nft-abbr {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #797979;
}

.user-nft-explorer-about-nft-details-connections {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #121212;
  border-bottom: 1px solid #dddddd;
  padding: 15px 20px;
}

.user-nft-explorer-about-nft-details-connections-contract {
  color: #056af9;
}

.user-nft-explorer-about-nft-details-connections-website {
  color: #056af9;
  cursor: pointer;
}

.user-nft-explorer-about-social-icons-widgets {
  margin-right: 3px;
}
