.user-nft-explorer-properties-wrapper {
  border-radius: 10px;
}

.user-nft-explorer-properties-nft-details {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.3);
}

.user-nft-explorer-properties-nft-details-header-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #121212;
  padding: 10px 20px;
  border-bottom: 1px solid #dddddd;
}

.user-nft-explorer-properties-nft-details-header-icon {
  margin-right: 5px;
}

.user-nft-explorer-properties-nft-details-connections {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #121212;
  border-bottom: 1px solid #dddddd;
  padding: 15px 20px;
}

.user-nft-explorer-properties-nft-details-connections-health-bar {
  height: 10px;
  background: #eeeeee;
  border-radius: 10px;
  display: flex;
  width: 100%;

  & > span {
    border-radius: 10px;
    display: block;
    height: 100%;
    background-color: #056af9;
    overflow: hidden;
  }
}

// .user-nft-explorer-properties-nft-details-connections-necklace {
//     background-color: #EEEEEE;
//     border-radius: 30px;
//     padding: 0px 5px;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     &::before {
//         content: '';
//         height: 12px;
//         width: 12px;
//         display: inline-block;
//         background-color: #056AF9;
//         border-radius: 50%;
//         margin-right: 5px;
//     }
// }
