@import "/src/styles/variables.scss";

.revisions {
  padding: 30px 0 55px;

  &Container {
    background-color: $color-white-0;
    border-radius: 10px;
  }

  &Heading {
    @include flex-center;
    justify-content: space-between;
    padding: 12px 30px;

    h2 {
      @include font-semibold-20;
      color: $color-black-1;
      @include flex-center;
      gap: 10px;
    }

    p {
      @include font-semibold-14;
      letter-spacing: 0.2px;
      color: $color-blue-0;
      cursor: pointer;
    }
  }

  &Content {
    h3 {
      @include font-semibold-16;
    }
  }

  &Title {
    padding: 12px 30px;
    border-top: 1px solid $color-grey-0;
    @include flex-center;
    gap: 52px;
  }

  .w-6 {
    width: 6%;
  }

  .w-94 {
    width: 94%;
  }

  &Row {
    padding: 12px 30px;
    border-top: 1px solid $color-grey-0;
    @include flex-center;
    gap: 52px;
    cursor: pointer;
    position: relative;

    &.active {
      &::after {
        transform: rotate(180deg);
      }
    }

    &::after {
      content: url(../../assets/images/home/downArrow.svg);
      position: absolute;
      right: 32px;
      transition: all 0.3s ease-in-out;
    }

    &Data {
      @include flex-center;
      gap: 4px;

      p {
        @include flex-center;
        gap: 5px;
      }
    }

    p {
      @include font-medium-16;
      color: $color-black-0;

      label {
        color: $color-grey-2;
        cursor: pointer;
      }
    }
  }

  .accordionAnswer {
    padding: 8px 28px 24px;
  }

  &Status {
    border: 1px solid $color-grey-1;
    border-radius: 10px;
  }

  &StatusRow {
    @include flex-center;
    gap: 10px;
    padding: 12px 24px;

    div {
      padding-right: 12px;
    }

    label {
      @include font-medium-14;
      color: $color-grey-3;
    }

    a {
      @include font-semibold-12;
      padding: 8px 12px;
      @include flex-center;
      gap: 4px;
      width: fit-content;
      border-radius: 16px;
    }

    h3 {
      @include flex-center;
      gap: 4px;
    }
  }

  .btnBlue {
    color: $color-white-0;
    background-color: $color-blue-1;
  }

  .commonBtn {
    color: $color-black-0;
    background-color: $color-white-0;
    border: 1.5px solid $color-black-0;
  }

  &ContentRow {
    display: flex;
    padding: 8px 24px;
    gap: 24px;
    border-top: 1px solid $color-grey-1;

    p {
      @include font-medium-16;
      color: $color-black-0;

      &:first-child {
        max-width: 90px;
        width: 100%;
      }
    }

    label {
      @include font-medium-16;
      color: $color-grey-2;
      display: block;
    }

    a {
      @include font-medium-16;
      display: inline-flex;
      color: $color-blue-0;
      align-items: center;
      gap: 4px;
      width: fit-content;
    }

    .show-more {
      cursor: pointer;
      @include font-medium-16;
      display: inline-flex;
      color: $color-blue-0;
      align-items: center;
      gap: 4px;
      width: fit-content;
    }
  }

  &ContentImg {
    @include flex-center;
    gap: 12px;
  }
}
