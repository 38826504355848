@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

.nft-transaction-overview-details {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #121212;
  display: flex;
  justify-content: left;
  border-bottom: 1px solid #dddddd;
  padding: 10px 0px;
}

.nft-transaction-overview-information-icon {
  border-radius: 50%;
  border: 1px solid black;
  height: 10px;
  width: 10px;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
}

.nft-transaction-overview-details-title {
  width: 150px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #121212;
}

.nft-transaction-overview-details-success {
  background: #e7ff89;
  border-radius: 10px;
  font-size: 12px;
  padding: 1px 5px;
  font-weight: bold;
  margin-right: 10px;
}

.nft-transaction-overview-details-proof-generated-time {
  font-weight: bold;
}

.nft-transaction-overview-details-confirmed-blocks {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #797979;
  background: #eeeeee;
  border-radius: 13px;
  padding: 2px 10px;
}

.nft-transaction-overview-details-nft-data {
  border: 1px dashed #36410c;
  background-color: #fbffeb;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.3;
  color: #000000;
  padding: 20px;
  width: calc(100% - 150px);
  letter-spacing: 0.2px;
  color: #000000;
}

.loader-dots {
  display:block !important;  
  position: relative;
  padding-right: 32px !important;
  &:after { 
    content:'....';
    width:0;
    position:absolute;
    bottom: 0;
    border-radius: 50%;
    overflow:hidden;
    font-size: 22px;
    animation:loader-dots-animation 1s infinite;     
  }
}

@keyframes loader-dots-animation {
  0%   { width:0.0em; }  
  50%  { width:1.2em; }      
  100% { width:0.0em; }    
}