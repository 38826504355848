.nft-transaction-proof-details {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #121212;
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  padding: 10px 0px;
}

.nft-transaction-proof-information-icon {
  border-radius: 50%;
  border: 1px solid black;
  height: 10px;
  width: 10px;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
}

.nft-transaction-proof-details-title {
  width: 150px;
}

.nft-transaction-proof-details-success {
  background: #e7ff89;
  border-radius: 10px;
  font-size: 12px;
  padding: 1px 5px;
  font-weight: bold;
  margin-right: 10px;
}

.nft-transaction-proof-details-proof-generated-time {
  font-weight: bold;
}

.nft-transaction-proof-details-download-proof-document {
  padding: 5px 13px 5px 10px;
  background: #056af9;
  border-radius: 20px;
  cursor: pointer;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nft-transaction-proof-details-verification-on-etherscan {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #056af9;
  border: 1px solid #056af9;
  padding: 5px 10px;
  border-radius: 30px;
  cursor: pointer;
}

.nft-explorer-verify-on-etherscan {
  :link {
    text-decoration: none;
    outline: none;
  }

  :visited {
    text-decoration: none;
    outline: none;
  }

}
