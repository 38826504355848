@media (min-width: 992px) {
  .home-container {
    width: 992px;
    margin: 0 auto;
  }
}

.home-wrapper {
  background: #ededed;
  min-height: 100vh;
  position: relative;
  padding-bottom: 366px;
.explorer-home-loader {
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translateX(-50%)
  }
}

.error {
  text-align: center;
  font-size: 32px;
  background-color: rgb(236, 104, 104);
  color: white;
  font-family: "Manrope";
  font-weight: 800;
  padding: 10px 20px;
  margin: 50px auto;
  border-radius: 10px;
}

.loader {
  font-family: "Manrope";
  font-weight: 800;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 20px;
  background-color: rgb(104, 36, 92);
}

.home-container {
  padding: 30px 0;
}

.nft-image {
  grid-area: NFT-IMAGE;
}

.nft-overview {
  grid-area: NFT-OVERVIEW;
}

.home-container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas:
    "NFT-IMAGE NFT-OVERVIEW NFT-OVERVIEW NFT-OVERVIEW"
    "NFT-IMAGE NFT-OVERVIEW NFT-OVERVIEW NFT-OVERVIEW"
    "NFT-IMAGE NFT-OVERVIEW NFT-OVERVIEW NFT-OVERVIEW";
  gap: 30px;
  margin: 20px auto;
}

#footer {
  position: absolute;
  height: 366px;
  bottom: 0;
  width: 100%;
  background: url("../../assets/images/background-image.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  object-fit: contain;
}
