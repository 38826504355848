// Colors
$color-white-0: #ffffff;
$color-white-1: #ededed;

$color-black-0: #000000;
$color-black-1: #121212;

$color-grey-0: #dddddd;
$color-grey-1: #cdcdcd;
$color-grey-2: #9a9a9a;
$color-grey-3: #9a9a9a;

$color-blue-0: #797979;
$color-blue-1: #056af9;

// Common Flex
@mixin flex-center {
  display: flex;
  align-items: center;
}

// Regular fonts

// Medium fonts
@mixin font-medium-14($line-height: 19px) {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: $line-height;
}
@mixin font-medium-16($line-height: 150%) {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: $line-height;
}

// Semi bold fonts
@mixin font-semibold-12($line-height: 16px) {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: $line-height;
}
@mixin font-semibold-14($line-height: 19px) {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: $line-height;
}
@mixin font-semibold-16($line-height: 22px) {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: $line-height;
}
@mixin font-semibold-20($line-height: 150%) {
  font-weight: 700;
  font-size: 2rem;
  line-height: $line-height;
}
