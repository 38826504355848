@media (min-width: 992px) {
  .nft-transaction-container {
    width: 992px;
    margin: 0 auto;
  }
}

.nft-transaction-wrapper {
  background: #ededed;
  min-height: 100vh;
  position: relative;
  padding-bottom: 366px;
}

.nft-transaction-container {
  // background: url('../../assets/images/nft-transaction-bg-image.svg');
  background: #ffffff;
  margin-top: 30px;
  border-radius: 10px;
  padding: 20px;
}

.nft-transaction-details {
  display: flex;
  font-family: "Manrope";
  font-weight: 700;
  font-size: 16px;
  color: #121212;
  border-bottom: 1px solid #dddddd;
}

.active-nft-transaction-tab::after {
  content: "";
  display: block;
  height: 2px;
  background: #056af9;
  margin: 10px 0 0 0;
  width: calc(100% + 30px);
  margin-left: -20px;
}

.nft-transaction-overview {
  margin-right: 50px;
  cursor: pointer;
}

.nft-transaction-proof {
  margin-right: 50px;
  cursor: pointer;
}

.nft-transaction-state {
  margin-right: 50px;
  cursor: default;
}

.nft-transaction-comments {
  margin-right: 50px;
  cursor: default;
}

.active-nft-transaction-content {
  display: block;
}

.nft-transaction-content {
  display: none;
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 366px;
}
