.footer-image {
  height: 1px;
  margin-top: 75px;
  background-color: #121212;
}

.footer-image img {
  position: relative;
  left: 50%;
  margin-top: -55px;
  transform: translate(-50%);
}

.footer-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #121212;
  width: 314px;
  margin: 0 auto;
  margin-top: 100px;
}

.footer-icons {
  text-align: center;
  margin: 20px auto;
  text-decoration: none;
}

.icon {
  margin-right: 30px;
  text-decoration: none;
  color: white;
}

.footer-bottom {
  height: 50px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: white;
  background: #121212;
  justify-content: center;
  display: flex;
  align-items: center;
}
