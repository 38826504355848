.header-wrapper {
  font-family: "Manrope";
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #bcbcbc;
}

.logo-home-navigator {
  text-decoration: none;
  cursor: pointer;

  &:visited {
    text-decoration: none;

    &:link {
      text-decoration: none;
    }
  }
}

.search-bar-wrapper {
  background-color: white;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
}

.input-search-bar {
  border: none;
  outline: none;
  width: 350px;

  &::placeholder {
    color: #bcbcbc;
    font-weight: 500;
    font-size: 14px;
  }
}

.arrow-right-search {
  cursor: pointer;
}
